body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fullscreen-bg {
  background-color: "white";
  height: "screen";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.code-green-bg {
  @apply bg-green-200;
}
.code-yellow-bg {
  @apply bg-yellow-200;
}
.code-red-bg {
  @apply bg-red-200;
}
.code-gray-bg {
  @apply bg-gray-200;
}

.code-green-text {
  @apply text-green-600;
}
.code-yellow-text {
  @apply text-yellow-600;
}
.code-red-text {
  @apply text-red-600;
}
.code-gray-text {
  @apply text-gray-600;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

@keyframes fill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-slide-up {
  animation: slideUp 0.5s ease-in-out forwards;
}

.infoBadge {
  position: relative;
  background-color: #71a3f2;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  overflow: hidden;
  z-index: 1;
}

.infoBadge::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #3b82f6;
  animation: fill 10s linear infinite;
  z-index: -1;
}
.red-blinking-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  animation: blinker 1s linear infinite;
}

.green-blinking-dot {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  animation: blinker 1s linear infinite;
}

@keyframes loadingAnimation {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.loading-bar {
  width: 100%;
  height: 4px;
  margin-top: -1px;
  background-color: gray;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
  background-size: 50% 100%;
  animation: loadingAnimation 1s linear infinite;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0px;
}
